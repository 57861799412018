import netlifyIdentity from 'netlify-identity-widget';
import React, {Component, Fragment} from 'react';
import 'node-fetch'
import {Link} from "react-router-dom";
import SavingsModal from "./Modal";
import {loginUser, logoutUser} from "../lib/identityActions";
import moment from 'moment';
import './savings.css';

const fetch = require('node-fetch');

export default class SpendTable extends Component {
	state = {
		spends: [],
		inputs: [],
		newSpend: {
			date: '',
			name: '',
			price: 0,
			user: ''
		},
		dispatching: false,
		showTopMenu: false,
		collapseDay: false,
		collapseWeek: true,
		collapseBeyond: true,
		todayTotal: '',
		weekTotal: '',
		beyondTotal: '',
		todaySpends: [],
		weekSpends: [],
		beyondSpends: [],
		showModal: false,}

	/**
	 * To essentially fake additional money added each day, we compare the last additions to the db
	 * with today's date.
	 *
	 * @param {date} a This is the datetime of the last created entry
	 * @param {date} b This is the datetime for opening the page
	 */
	compareTimePassed = (a, b) => {

		// eslint-disable-next-line
		Date.prototype.toIsoString = function () {
			var tzo = -this.getTimezoneOffset(), dif = tzo >= 0 ? '+' : '-', pad = function (num) {
				var norm = Math.floor(Math.abs(num));
				return (norm < 10 ? '0' : '') + norm;
			};
			return this.getFullYear() + '-' + pad(this.getMonth() + 1) + '-' + pad(this.getDate()) + 'T' + pad(this.getHours()) + ':' + pad(this.getMinutes()) + ':' + pad(this.getSeconds()) + dif + pad(tzo / 60) + ':' + pad(tzo % 60);
		}
		let date1 = new Date(a);
		let date2 = new Date(b);
		date1 = date1.toIsoString().split('T')[0];
		date2 = date2.toIsoString().split('T')[0];

		const oldDate = new Date(date1);
		const newDate = new Date(date2);

		// To calculate the time difference of two dates
		const diffInTime = newDate.getTime() - oldDate.getTime();

		// To calculate the number of days between two dates
		const diffInDays = diffInTime / (1000 * 3600 * 24);

		if (diffInDays !== 0) {

			//Grab the userID for the new entry
			const userId = netlifyIdentity.currentUser();
			const dayArray = [...Array(diffInDays).keys()];
				dayArray.forEach((i) => {

				const m = moment(date1).add(i + 1,'d');
				const date = m.toISOString();

				this.setState({
					newSpend: {
						date: date,
						name: 'Daily Increase',
						price: 30,
						user: userId.id,
					},
				});

				// If there have been any days that have passed, increase by the appropriate amount

				this.handleCreate();

			})

		}
	}

	componentDidMount = () => {

		this.setState({
			dispatching: true,
		});

		const user = localStorage.getItem("currentUser");
		if (user) {
			this.setState({user: JSON.parse(user)});
			this.grabExistingInputs();
		} else {
			loginUser();
		}

		netlifyIdentity.on("login", (user) => this.setState({user}, loginUser()));
		netlifyIdentity.on("logout", (user) => this.setState({user: null}, logoutUser()));

		this.setState({
			showTopMenu: false
		})
	}

	grabExistingInputs = () => {
		const user = localStorage.getItem("currentUser");
		this.setState({
			dispatching: true,
		});

		// Fetch the spends from the database
		fetch(`/.netlify/functions/spendRead/?user=` + JSON.parse(user).id).then(res => res.json()).then(response => {
			console.log(response)
			if (response && response.data && response.data.length === 0) {

				this.setState({
					newSpend: {
						date: moment().toISOString(),
						name: 'Starting Allotment',
						price: 30,
						user: JSON.parse(user).id,
					},
				})
				this.handleCreate();
				this.setInitialView();

			} else {

				//Handle the per diem additional input
				const now = new Date();
				const nowDate = now.toISOString();
				const oldDate = response.data.slice(0, 1)[0].date;

				this.compareTimePassed(oldDate, nowDate);

				const inputs = [...this.state.inputs], spends = response.data;

				spends.forEach(spend =>
				{
					const spendProps = this.setSpendProps(spend)
					inputs.push(spendProps)
				});

				this.setState({
					spends,
					inputs,
					dispatching: false,
				})
			}

			this.setInitialView();

		}).catch(err => {
			console.log(JSON.stringify(err))
			//console.log('Error retrieving spends: ', err));
	})


	}

	/**
	 * Set the initial view for the different sets of items based on their creation date,
	 * collapsing weekly and beyond to start
	 */
	setInitialView = () => {

		const todaySpends = [...this.state.todaySpends];
		const weekSpends = [...this.state.weekSpends];
		const beyondSpends = [...this.state.beyondSpends];

		this.state.inputs.map((spend, i) =>
		{
			const isToday = spend.date && moment(spend.date).isSameOrAfter(moment().startOf('day'));
			const isThisWeek = spend.date && moment(spend.date).subtract(1, 'days').isAfter(moment().subtract(7, 'days'));

			if ( isToday === true ) {
				return todaySpends.push(spend);
			} else if ( isThisWeek === true ) {
				return weekSpends.push(spend);
			} else {
				return beyondSpends.push(spend);
			}
		});

		this.setState({
			...this.state,
			todaySpends: todaySpends,
			weekSpends: weekSpends,
			beyondSpends: beyondSpends
		})

		this.setTotals();

	}

	spendFooter = (spend) => {
		if(spend.price && Math.sign(spend.price) === -1) {
			return(
				<div className="item-tabled">
					<div className="spend__date-display">{moment(spend.date).format('MM/DD/YYYY')}</div>
					<div className="spend__spent">Spent</div>
				</div>
			)
		} else {
			return (
				<div className="item-tabled">
					<div className="spend__date-display">{moment(spend.date).format('MM/DD/YYYY')}</div>
					<div className="spend__added">Added</div>
				</div>
			)
		}

	}

	buildFullDaySpends = () => {

		const thisDaySpends = this.state.todaySpends.map((spend, i) => {

			return (
				<div
					key={'spend_' + i}
					className={this.state.collapseDay === false ? 'spend__today spend__finalized-section' : 'spend__today spend__finalized-section none'}
					onClick={this.handleCollapse}
				>
					<div id={spend.date ? 'date' : ''}>
						<span className="spend__finalized-name"><input name="name" data-id={i} value={spend.name} onChange={this.handleInputChange} disabled/></span>
						<span className="spend__finalized-price"><input name="price" data-id={i} value={spend.price / 100} onChange={this.handleInputChange} disabled/></span>
						{this.spendFooter(spend)}
					</div>
					<span className="spend__update-remove">
						{this.compareSpendProps(i) ?
							<button className="spend__update" data-id={i} onClick={this.handleUpdate}>Update</button> :
							<button className="spend__remove" data-id={i} onClick={this.handleDelete}/>
						}
					</span>
				</div>
			)

		})
		return thisDaySpends;

	}

	buildFullWeekSpends = () => {

		const thisWeekSpends = this.state.weekSpends.map((spend, i) => {

			return (

				<div
					key={'spend_' + i}
					className={this.state.collapseWeek === false ? 'spend__this-week spend__finalized-section' : 'spend__this-week spend__finalized-section none'}
					onClick={this.handleCollapse}
				>
					<div id={spend.date ? 'date' : ''}>
						<span className="spend__finalized-name"><input name="name" data-id={i} value={spend.name} onChange={this.handleInputChange} disabled/></span>
						<span className="spend__finalized-price"><input name="price" data-id={i} value={spend.price / 100} onChange={this.handleInputChange} disabled/></span>
						{this.spendFooter(spend)}
					</div>
					<span className="spend__update-remove">
						{this.compareSpendProps(i) ?
							<button className="spend__update" data-id={i} onClick={this.handleUpdate}>Update</button> :
							<button className="spend__remove" data-id={i} onClick={this.handleDelete}/>
						}
					</span>
				</div>

			)

		});

		return thisWeekSpends;
	}

	buildFullBeyondSpends = () => {

		const theBeyondSpends = this.state.beyondSpends.map((spend, i) => {

			return (

				<div
					key={'spend_' + i}
					className={this.state.collapseBeyond === false ? 'spend__before-week spend__finalized-section' : 'spend__before-week spend__finalized-section none'}
					onClick={this.handleCollapse}
				>

					<div id={spend.date ? 'date' : ''}>
						<span className="spend__finalized-name"><input name="name" data-id={i} value={spend.name} onChange={this.handleInputChange} disabled/></span>
						<span className="spend__finalized-price"><input name="price" data-id={i} value={spend.price / 100} onChange={this.handleInputChange} disabled/></span>
						{this.spendFooter(spend)}
					</div>
					<span className="spend__update-remove">{this.compareSpendProps(i) ?
						<button className="spend__update" data-id={i} onClick={this.handleUpdate}>Update</button> :
						<button className="spend__remove" data-id={i} onClick={this.handleDelete}/>
					}
					</span>
				</div>
			)

		});

		return theBeyondSpends;
	}

	renderCollapsedDay = () => {

		return (

			<div
				className={'spend__today-collapsed spend__finalized-section'}
				onClick={this.handleCollapse}
			>
				<div>
					<span className="spend__finalized-name"><input name="name" value="Total for Today" disabled/></span>
					<span className="spend__finalized-price"><input name="price" value={this.state.todayTotal} disabled/></span>
					{this.state.todayTotal <= 0 ? <div className="item-tabled">Spent</div> :
						<div className="item-tabled">Added</div>}
				</div>
				<span className="spend__update-remove-collapsed" />
			</div>

		)

	}

	renderCollapsedWeek = () => {

		return (

			<div
				className={'spend__this-week-collapsed spend__finalized-section'}
				onClick={this.handleCollapse}
			>
				<div>
					<span className="spend__finalized-name"><input name="name" value="Total for the Week" disabled/></span>
					<span className="spend__finalized-price"><input name="price" value={this.state.weekTotal} disabled/></span>
					{this.state.weekTotal <= 0 ? <div className="item-tabled">Spent</div> :
						<div className="item-tabled">Added</div>}
				</div>
				<span className="spend__update-remove-collapsed" />
			</div>

		)

	}

	renderCollapsedBeyond = () => {

		return (

			<div
				className={'spend__before-week-collapsed spend__finalized-section'}
				onClick={this.handleCollapse}
			>
				<div>
					<span className="spend__finalized-name"><input name="name" value="Total for Beyond" disabled/></span>
					<span className="spend__finalized-price"><input name="price" value={this.state.beyondTotal} disabled/></span>
					{this.state.beyondTotal <= 0 ? <div className="item-tabled">Spent</div> :
						<div className="item-tabled">Added</div>}
				</div>
				<span className="spend__update-remove-collapsed" />
			</div>

		)

	}

	setTotals = () => {

		//Get day total
		let todayTotal = [...document.getElementsByClassName('spend__today')];
		let totalOfDay = 0;
		todayTotal.map(spend => {
			let total = parseInt(spend.firstElementChild.children[1].children[0].value * 100);
			return totalOfDay = totalOfDay + total;
		});

		this.setState({
			todayTotal: totalOfDay / 100,
		});

		// Get week total
		let weekTotal = this.state.weekSpends;
		let totalOfWeek = 0;
		weekTotal.map(spend => {
			let total = parseInt(spend.price);
			return totalOfWeek = totalOfWeek + total;
		});

		this.setState({
			weekTotal: totalOfWeek / 100,
		});

		// Get beyond total
		let beyondTotal = this.state.beyondSpends;
		let totalOfBeyond = 0;
		beyondTotal.map(spend => {
			let total = parseInt(spend.price);
			return totalOfBeyond = totalOfBeyond + total;
		});

		this.setState({
			beyondTotal: totalOfBeyond / 100,
		});

	}

	// SpendProps
	setSpendProps = (spend) => {
		const spendProps = {}

		// Cycle through spend props and exclude props we don't want shown in the table
		Object.keys(spend).forEach(key => {
			if (key !== '_id' && key !== '__v') {
				spendProps[key] = spend[key]
			}
		})

		return spendProps;
	}

	compareSpendProps = (i) => {
		const spend = this.state.spends[i], input = this.state.inputs[i]

		let compare = false

		// Cycle though spend props and exclude important props from comparison
		if (spend) {
			Object.keys(spend).forEach(key => {
				if (key !== '_id' && key !== '__v') {
					if (spend[key] !== input[key]) {
						// Found a difference between input and spend
						compare = true;
					}
				}
			})
			return compare;
		}

	}

	// Input handlers
	handleNewInputChange = (e) => {
		const userId = netlifyIdentity.currentUser();

		const newSpend = this.state.newSpend, name = e.target.name,
			value = e.target.value, user = userId.id;

		newSpend[name] = value;
		newSpend.user = user;
		newSpend.date = moment().toISOString();

		this.setState({
			newSpend: newSpend,
		})

	}

	handleInputChange = (e) => {
		const inputs = [...this.state.inputs], target = e.target, id = parseInt(target.dataset.id), name = target.name,
			value = target.value;

		inputs[id][name] = value;

		this.setState({
			inputs,
		})
	}

	//TODO combine this with regular create - so much inefficiency
	handleNewNegativeInputChange = () => {

		this.setState({
			dispatching: true
		});

		const newSpend = this.state.newSpend;
		const userId = netlifyIdentity.currentUser();
		newSpend.price = newSpend.price * 100;
		newSpend.price = parseInt(-newSpend.price);

		const updatedToday = [...this.state.todaySpends];
		updatedToday.unshift(this.state.newSpend);

		this.setState({
			...this.state,
			todaySpends: updatedToday,
		});

		this.postAPI('spendCreate', newSpend).then(response => {
			console.log(response.msg)

			const spend = response.data;
			const spends = [...this.state.spends];
			const inputs = [...this.state.inputs];

			const newSpend = {
				date: '',
				name: '',
				price: 0,
				user: userId.id,
			};
			const spendProps = this.setSpendProps(spend);

			inputs.unshift(spendProps)
			spends.unshift(spend)

			this.setState({
				spends: spends,
				inputs: inputs,
				newSpend: newSpend,
				dispatching: false,
			})
		}).catch(err => console.log('Spend.create API error: ', err))
	}

	// CRUD
	postAPI = (source, data) => {
		return fetch('/.netlify/functions/' + source, {
			method: 'post',
			body: JSON.stringify(data),
		}).then(res => res.json()).catch(err => err)
	}

	// CRUD Handlers
	handleCreate = () => {

		this.setState({
			dispatching: true
		});

		const newSpend = this.state.newSpend;
		newSpend.price = newSpend.price * 100;

		const today = moment();

		const newSpendDate = this.state.newSpend.date;
		const updatedToday = [...this.state.todaySpends];
		const updatedWeek = [...this.state.weekSpends];
		const updatedBeyond = [...this.state.beyondSpends];

		if(moment(newSpendDate).isSame(today, 'day')){
			updatedToday.unshift(this.state.newSpend);
		} else if (moment().diff(newSpendDate, 'days') <= 7) {
			updatedWeek.unshift(this.state.newSpend);
		} else {
			updatedBeyond.unshift(this.state.newSpend);
		}

		this.setState({
			...this.state,
			todaySpends: updatedToday,
			weekSpends: updatedWeek,
			beyondSpends: updatedBeyond
		});

		this.postAPI('spendCreate', newSpend).then(response => {
			const userId = netlifyIdentity.currentUser();
			const spend = response.data;
			const spends = [...this.state.spends];
			const inputs = [...this.state.inputs];
			const newSpend = {
				date: '',
				name: '',
				price: 0,
				user: userId.id,
			};
			const spendProps = this.setSpendProps(spend);

			inputs.unshift(spendProps);
			spends.unshift(spend);

			this.setState({
				spends: spends,
				inputs: inputs,
				newSpend: newSpend,
				dispatching: false,
			})
		}).catch(err => console.log('Spend.create API error: ', err))
	}

	// TODO figure out if I want to keep this or not
	handleUpdate = (e) => {
		const spends = [...this.state.spends], inputs = [...this.state.inputs], index = parseInt(e.target.dataset.id),
			spendData = inputs[index], oid = this.state.spends[index]._id;

		// Set spend id and spend data as JSON string
		const data = JSON.stringify({
			id: oid,
			spend: spendData,
		});

		this.postAPI('spendUpdate', data).then(response => {
			const spend = response.data;

			// Set updated spend props
			inputs[index] = this.setSpendProps(spend);
			spends[index] = spend;

			this.setState({
				spends,
				inputs,
			})
		}).catch(err => console.log('Spend.delete API error: ', err))
	}

	handleDelete = (e) => {
		let index;

		if(typeof e == 'number') {
			index = e
		} else {
			index = parseInt(e.target.dataset.id)
		}

		const id = this.state.spends[index]._id;
		const timeStamp = this.state.spends[index].date;
		const todaySpends = [...this.state.todaySpends];
		const weekSpends = [...this.state.weekSpends];
		const beyondSpends = [...this.state.beyondSpends];

		this.state.todaySpends.map((spend, i) => {
			console.log('hi' + i)
			if(spend.date === timeStamp) {
				console.log('hi' + spend)
				return todaySpends.splice(i, 1)
			}
			return null
		})

		this.state.weekSpends.map((spend, i) => {
			if(spend.date === timeStamp) {
				return weekSpends.splice(i, 1)
			}
			return null
		})

		this.state.beyondSpends.map((spend, i) => {
			if(spend.date === timeStamp) {
				return beyondSpends.splice(i, 1)
			}
			return null
		})

		this.postAPI('spendDelete', id).then(response => {
			console.log(response.msg);

			const inputs = [...this.state.inputs];
			const spends = [...this.state.spends];

			inputs.splice(index, 1);
			spends.splice(index, 1);

			this.setState({
				spends: spends,
				inputs: inputs,
				todaySpends: todaySpends,
				weekSpends: weekSpends,
				beyondSpends: beyondSpends,
			})
		}).catch(err => console.log('Spend.delete API error: ', err))

	}

	// Check for netlify logged in
	handleLogIn = () => {
		netlifyIdentity.open('login');
	}

	//Open signup modal
	handleSignUp = () => {
		netlifyIdentity.open('signup');
	}

	// Handle netlify log out
	handleLogOut = () => {
		netlifyIdentity.logout();
		this.setState({
			showTopMenu: false
		})
	}

	/**
	 * Builds the sum for the overall total available amount
	 *
	 * @returns {*}
	 */
	handleCalculation = () => {
		let sum = 0;
		this.state.inputs.map((spend, i) => {
			Object.keys(spend).map(key => {
				if (key === 'price') {
					return sum += spend[key];
				}
				return null
			})
			return null
		})
		//sum = sum
		return <span>{sum / 100}</span>
	}

	//Take all the existing entries and move them to the archived page
	archiveExistingSpends = () => {

	}

	//Take all existing spends and get rid of them
	openModal = (type) => {

		this.setState({
			showModal: true,
			modalName: type
		})
		document.getElementsByTagName("BODY")[0].style.overflow = "hidden"

	}

	/**
	 * If this togglable state is set to true, the top menu will be visible
	 */
	showTopMenu = () => {

		this.setState({
			showTopMenu: !this.state.showTopMenu,
		})

	}

	/**
	 * Renders the top menu
	 */
	renderTopButtons = () => {
		// TODO add back in when I have more settings to ...set
		//<div className={!this.state.showTopMenu ? "top__action-buttons-bank top__action-buttons-bank-inactive" : "top__action-buttons-bank top__action-buttons-bank-active"} onClick={() => this.openModal('Bank')}>Bank</div>


		return (

			<Fragment>
				<div className="top__action-buttons-beer"><a href="https://www.buymeacoffee.com/maryeellis"><span role='img' alt='beer mug' aria-label='beer mug'>🍺</span></a></div>
				<div className="top__action-buttons-beer-text"><a href="https://www.buymeacoffee.com/maryeellis">Beer me!</a></div>
				<div className={!this.state.showTopMenu ? "top__action-buttons top__action-buttons-inactive" : "top__action-buttons top__action-buttons-active"} onClick={() => this.showTopMenu()}/>
				<div className={!this.state.showTopMenu ? "top__action-buttons-bust top__action-buttons-bust-inactive" : "top__action-buttons-bust top__action-buttons-bust-active"} onClick={() => this.openModal('Bust')}>Bust</div>
				<div className={!this.state.showTopMenu ? "top__action-buttons-bank top__action-buttons-about-inactive" : "top__action-buttons-about top__action-buttons-about-active"}><Link to='/about'>About</Link></div>
				<div className={!this.state.showTopMenu ? "top__action-buttons-bank top__action-buttons-logout-inactive" : "top__action-buttons-logout top__action-buttons-logout-active"} onClick={this.handleLogOut}>Logout</div>

			</Fragment>

		)

	}

	/**
	 * Renders the two bottom buttons
	 */
	renderBottomButtons = () => {

		return (

			<div className="bottom__action-buttons">
				<div className="bottom__action-buttons-bank" onClick={() => this.openModal('Bank')}>Bank</div>
				<div className="bottom__action-buttons-bust" onClick={() => this.openModal('Bust')}>Bust</div>
			</div>

		)

	}

	handleCollapse = (e) => {
		const targetedSection = typeof e === 'object' ? e.target.className : e;

		// Find out what is or isn't collapsed based on classname
		switch(targetedSection){
			case 'spend__today spend__finalized-section':

				this.setState({
					collapseDay: !this.state.collapseDay,
				})
				break;

			case 'spend__this-week spend__finalized-section':

				this.setState(prevState => ({collapseWeek: !this.state.collapseWeek}))
				break;

			case 'spend__today-collapsed spend__finalized-section':

				this.setState({
					collapseDay: !this.state.collapseDay,
				})
				break;

			case 'spend__this-week-collapsed spend__finalized-section':

				this.setState({
					collapseWeek: !this.state.collapseWeek,
				})
				break;

			case 'spend__before-week spend__finalized-section':

				this.setState({
					collapseBeyond: !this.state.collapseBeyond,
				})
				break;

			case 'spend__before-week-collapsed spend__finalized-section':

				this.setState({
					collapseBeyond: !this.state.collapseBeyond,
				})
				break;

			default:
				this.setState({
					collapseBeyond: !this.state.collapseBeyond,
				})
				break;

		}
	}

	deleteAllSpends = () => {

		this.setState({
			dispatching: true
		})

		this.state.inputs.forEach((spend, i) => {
			const id = this.state.spends[i]._id;

			this.postAPI('spendDelete', id).then(response => {
				console.log(response.msg);
			}).catch(err => console.log('Spend.delete API error: ', err))

			this.setState({
				spends: [],
				inputs: [],
				todaySpends: [],
				weekSpends: [],
				beyondSpends: [],
			})
		})

		this.toggleModal();
		this.grabExistingInputs();

	}

	/**
	 * Take the type of modal passed and render modal component accordingly
	 *
	 * @param type
	 * @returns {*}
	 */
	handleShowModal = (type) => {
		return (
			<SavingsModal type={type} close={() => this.toggleModal()} delete={() => this.deleteAllSpends()} archive={() => this.archiveExistingSpends()}/>
		)
	}

	toggleModal = () => {
		this.setState({
			showModal: false
		})
		document.getElementsByTagName("BODY")[0].style.overflow = "auto"
	}

	isMobile = () => {
		var check = false;
		(function(a){
			// eslint-disable-next-line
			if(/(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(a)||/1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(a.substr(0,4)))
				check = true;
		})(navigator.userAgent||navigator.vendor||window.opera);
		return check;
	};

	render() {
		const userId = netlifyIdentity.currentUser();
		const showDaily = this.state.todaySpends.length > 0 ? this.state.collapseDay === false ? this.buildFullDaySpends() : this.renderCollapsedDay() : '';
		const showWeekly = this.state.weekSpends.length > 0 ? this.state.collapseWeek === false ? this.buildFullWeekSpends() : this.renderCollapsedWeek() : '';
		const showBeyond = this.state.beyondSpends.length > 0 ? this.state.collapseBeyond === false ? this.buildFullBeyondSpends() : this.renderCollapsedBeyond() : '';


		return (

			<div className="spend-container">
				{this.state.showModal ? this.handleShowModal(this.state.modalName) : ''}
				{userId ? this.renderTopButtons() : ''}
				<div className="spend-image">
					<img src="iconfinder_starship.png" alt="Blasting off spaceship showing the saving and earning potential you have"/>
				</div>
				<h1>Bank or Bust</h1>
				{userId ? <div className="spend__main">

						<div className="spend__main-header">Total Available to Spend: ${this.handleCalculation()}</div>
						<div className="spend__add-section">
							<div className="spend__add-inputs">
								<span>Spend Name</span>
								<input name='name' type='string' value={this.state.newSpend.name} onChange={this.handleNewInputChange}/>
							</div>
							<div className="spend__add-inputs">
								<span>Price</span>
								<div className="dollar"><input name='price' type='number' value={this.state.newSpend.price} onChange={this.handleNewInputChange} inputMode='numeric' pattern='[0-9]*' min='0' step='0.01'/></div>
								<input type='hidden' value={Date.now()}/>
							</div>

						</div>

						<div className="spend__action-buttons">
							<button onClick={this.handleNewNegativeInputChange}>Spent</button>
							<button onClick={this.handleCreate}>Incoming!</button>
						</div>
						{this.state.dispatching === false ? <div id="loading"/> : <div className="spend__spend-table">
							<div className={!this.state.collapseDay ? "spend__daily-section spend__daily-section-active" : "spend__daily-section spend__daily-section-inactive"}>{showDaily}</div>
							<div className={!this.state.collapseWeek ? "spend__weekly-section spend__weekly-section-active" : "spend__weekly-section spend__weekly-section-inactive"}>{showWeekly}</div>
							<div className={!this.state.collapseBeyond ? "spend__beyond-section spend__beyond-section-active" : "spend__beyond-section spend__beyond-section-inactive"}>{showBeyond}</div>
						</div>}
						{this.renderBottomButtons()}
					</div>
					:
					<div>
						<div className="spend__logged-out spend__main-header" onClick={this.handleLogIn}>Login to keep saving</div>
						<div className="spend__logged-out spend__main-header">or</div>
						<div className="spend__logged-out spend__main-header spend__main-signup" onClick={this.handleSignUp}>Sign up to start saving!</div>
					</div>
				}
			</div>

		)

	}

}
