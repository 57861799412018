import React, {Component} from 'react';
import {
	Link,
	Redirect
} from "react-router-dom";
import './onesheeter.css';
import netlifyIdentity from 'netlify-identity-widget';
import {loginUser} from "../lib/identityActions";

export default class OneSheeter extends Component
{

	constructor(props)
	{
		super(props);
		this.state = {};
	}

	componentDidMount() {
		netlifyIdentity.on("login", (user) => this.setState({user}, loginUser()));
	}

	// Check for netlify logged in
	handleLogIn = () => {
		const user = localStorage.getItem("currentUser");

		if (user) {
			this.setState({user: JSON.parse(user)});
		} else {
			netlifyIdentity.open('login');
		}
	}

	//Open signup modal
	handleSignUp = () => {
		netlifyIdentity.open('signup');
	}

	render() {

		if(this.state.user){ return <Redirect to='/' /> }

		return (
			<div className="main">
				<main className="wrapper">
					<div className="intro__section">
						<div className="about-image">
							<img src="iconfinder_planets.png" alt="Blasting off spaceship showing the saving and earning potential you have"/>
						</div>
						<h1>Bank or Bust</h1>
						<h2>Simplifying savings</h2>
						<div className="about__existing">
							<p className="about__existing-crushin">Already crushin it?</p>
							<p className="about__existing-sign-in" onClick={this.handleLogIn}>Sign In</p>
						</div>
						<span className="about about__1">Track your spending - that's it!</span>
					</div>
					<section className="section parallax bg1">
					</section>
					<section className="section static about">
						<span className="about__2">You're tired of hooking up bank <span role='img' alt='bank emoji' aria-label='bank emoji'>🏦</span> accounts...</span>
						<img className="star1" src='star.png' alt='star'/>
						<img className="star2" src='star.png' alt='star'/>
						<img className="star3" src='star.png' alt='star'/>
						<img className="star4" src='star.png' alt='star'/>
					</section>
					<section className="section parallax bg2">
						<span className="about about__3">...you don't need to categorize that fast food...again...<span role='img' alt='french fry container' aria-label='french fry container'>🍟</span></span>
					</section>
					<section className="section static2">
						<span className="about about__2">...you just want to know if you're within bounds.</span>
						<img className="star1" src='star.png' alt='star'/>
						<img className="star2" src='star.png' alt='star'/>
						<img className="star3" src='star.png' alt='star'/>
						<img className="star4" src='star.png' alt='star'/>
					</section>
					<section className="section2">
						<span className="about about__4">Meet the web app that simply tracks the money you spend (or find <span role='img' alt='fingers crossed emoji' aria-label='fingers crossed emoji'>🤞</span>) against a daily amount</span>
						<div className="signup-image">
							<img src="iconfinder_starship.png" alt="Blasting off spaceship showing the saving and earning potential you have"/>
						</div>
						<div className="signup__section">
							<h1>Bank or Bust</h1>
							<h2><Link to="/">Get started</Link></h2>
						</div>
					</section>
					<div className="about-footer">
						<div className="footer-item">Made with <span role='img' alt='yellow heart emoji' aria-label='yellow heart emoji'>💛</span>in Brooklyn</div>
						<hr/>
						<div className="footer-item">Stack: React * Netlify * mLab</div>
						<div className="footer-item">Netlify Functions * Netlify Identity</div>

					</div>
				</main>
			</div>
		)
	}

}
