import React, {Component} from 'react';
import ToggleSwitch from "./ToggleSwitch";

import './modal.css';
import netlifyIdentity from "netlify-identity-widget";

export default class SavingsModal extends Component {

	constructor(props) {
		super(props);
		this.state = {
			inputs: [],
			settings: [],
			newSettings: {
				dailyAllotment: ''
			}
		};
	}

	componentDidMount() {

	}

	// CRUD
	postAPI = (source, data) => {
		return fetch('/.netlify/functions/' + source, {
			method: 'post',
			body: JSON.stringify(data),
		}).then(res => res.json()).catch(err => err)
	}

	handleCreateOrUpdate = () => {

		this.setState({
			dispatching: true
		});

		const newSettings = this.state.newSettings;
		newSettings.dailyAllotment = newSettings.dailyAllotment * 100;

		this.postAPI('settingsCreate', newSettings).then(response => {
			const userId = netlifyIdentity.currentUser();
			//const spend = response.data;
			const settings = [...this.state.settings];
			const inputs = [...this.state.inputs];
			const newSettings = {
				settingName: '',
				dailyAllotment: 0,
				user: userId.id,
			};
			const settingsProps = this.setSettingsProps(settings);

			inputs.unshift(settingsProps);
			settings.unshift(settings);

			this.setState({
				settings: settings,
				inputs: inputs,
				newSettings: newSettings,
				dispatching: false,
			})
		}).catch(err => console.log('Spend.create API error: ', err))
	}

	handleNewInputChange = (event) => {
		const userId = netlifyIdentity.currentUser();

		const newSettings = this.state.newSettings, settingName = event.target.name,
			value = event.target.value, user = userId.id;

		newSettings[settingName] = value;
		newSettings.user = user;

		this.setState({
			newSettings: newSettings,
		})
	}

	onToggleChange = (event) => {
		console.log(event);
	}

	renderTypeOfModal = (type) => {
		switch (type) {
			case 'Bust':
				return (
					<div className="modal__content-bust">
						<h2 className="modal__header">Don't run - it's ok! We all need a clean slate sometimes</h2>
						<img src="iconfinder_alien.png" alt="Cute stylized alien rushing away" />
						<div className="modal__message">Click below to start from scratch</div>
						<div className="modal__footer-buttons">
							<div className="modal__cancel-button" onClick={() => this.props.close()}>Cancel</div>
							<div className="modal__accept-button" onClick={() => this.props.delete()}>Start clean</div>
						</div>
					</div>
				);
			case 'Bank':
				return (
					<div className="modal__content-bank">
						<h2 className="modal__header">You saved it and no one can take that away!</h2>
						<img src="iconfinder_alien.png" alt="Cute stylized alien rushing away" />
						<div className="modal__message">Click to archive your wins</div>
						<div className="modal__footer-buttons">
							<div className="modal__cancel-button" onClick={() => this.props.close()}>Cancel</div>
							<div className="modal__accept-button" onClick={() => this.props.archive()}>Bank it!</div>
						</div>
					</div>
				);
			case 'Settings':
				return (
					<div className="modal__content-bank">
						<h2 className="modal__header">Customize your needs:</h2>
						<img src="../../public/iconfinder_planets.png" alt="Cute stylized alien rushing away" />
						<div className="modal__message">Click to archive your wins</div>
						<div className="modal__footer-buttons">
							<form>
								<label name="dailyAllotment" type="number">Daily Allotment
									<input name='dailyAllotment' type='number' value={this.state.newSettings.dailyAllotment} onChange={this.handleNewInputChange} inputMode='numeric' pattern='[0-9]*' min='0' step='0.01'/>
								</label>
							<div>
								<ToggleSwitch
									id="auto-archive"
									defaultChecked={false}
									disabled={false}
									Text={["Yes", "No"]}
									onToggleChange={this.onToggleChange}
								/>
							</div>
							</form>
							<div className="modal__cancel-button" onClick={() => this.props.close()}>Cancel</div>
							<div className="modal__accept-button" onClick={() => this.handleCreateOrUpdate()}>Save</div>
						</div>
					</div>
				);
			default:
				return (
					<div className="modal__header">Hi</div>
				)
		}
	}

	render() {

		return (
			<div className="modal__main">
				<div className="modal__close" onClick={() => this.props.close()}>x</div>
				{this.renderTypeOfModal(this.props.type)}
			</div>
		)
	}

}
