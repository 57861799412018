import React from 'react';
import Savings from './components/Savings';
import OneSheeter from './components/OneSheeter';
import {
	BrowserRouter as Router,
	Switch,
	Route
} from "react-router-dom";


export default function App() {
	return (
		<Router>
			<div>
				<Switch>
					<Route exact path="/">
						<Home />
					</Route>
					<Route path="/about">
						<About />
					</Route>
				</Switch>
			</div>
		</Router>
	);
}

// You can think of these components as "pages"
// in your app.

function Home() {
	return (
		<Savings />
	);
}

function About() {
	return (
		<OneSheeter/>
	);
}

//function Dashboard() {
//	return (
//		<div>
//			<h2>Dashboard</h2>
//		</div>
//	);
//}
